const BACKLOG = 'Backlog'
const TODO = 'To do'
const DOING = 'Doing'
const READYFORREVIEW = 'Ready for review'
const DEV = 'Dev'
const TEST = 'Test'
const STAGING = 'Staging'
const ARCHIVE = 'Archive'
const PRODUCTION = 'Production'
const FINISHED = 'Finished'
export const allTypes = [BACKLOG, TODO, DOING, READYFORREVIEW, DEV, TEST, STAGING, ARCHIVE, PRODUCTION, FINISHED]
export const allKanbanTypes = [BACKLOG, TODO, DOING, READYFORREVIEW, DEV, TEST, STAGING, ARCHIVE, PRODUCTION]
export {
    BACKLOG,
    TODO,
    DOING,
    READYFORREVIEW,
    DEV,
    TEST,
    STAGING,
    ARCHIVE,
    PRODUCTION,
    FINISHED,
}

export default {
    BACKLOG,
    TODO,
    DOING,
    READYFORREVIEW,
    DEV,
    TEST,
    STAGING,
    ARCHIVE,
    PRODUCTION,
    FINISHED,
    allTypes,
    allKanbanTypes
}